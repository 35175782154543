<template>
  <business-data
    category="business"
    title="Business"
  />
</template>

<script>
  export default {
    name: 'DashboardBusinessData',

    components: {
      BusinessData: () => import('../component/Category'),
    }
  }
</script>
